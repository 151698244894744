import React from 'react'
import Sidebar from '../Sidebar'
import Topbar from '../Topbar'
import Footer from '../Footer'
import Alert from '../Alert'
import LoadingOverlay from 'react-loading-overlay'
import { withFirebase } from '../Firebase'

export const withLayout = (Component, LayoutSidebar = Sidebar) => {

  class Layout extends React.Component {
    constructor(props) {
      super(props)

      this.state = {
        alert: {
          content: '',
          type: 'success'
        },
        isLoading: false
      }
      this.setAlert = this.setAlert.bind(this)
      this.isLoading = this.isLoading.bind(this)
    }

    setAlert(content, type = 'success') {
      if (this.state.alert.content === content && this.state.alert.type === type) return
      this.setState({alert: {content, type}})
    }

    isLoading(value, callback) {
      if (value !== this.state.isLoading)
        this.setState({isLoading: value}, callback)
        else if (callback) callback()
    }

    render() {
      return (
        <LoadingOverlay
          active={this.props.firebase.currentUser === undefined || this.state.isLoading}
          spinner
          >
        <div id="wrapper">
          <LayoutSidebar />

          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <Topbar isLoading={this.isLoading} />
              <div className="container-fluid">
                <Alert content={this.state.alert.content} type={this.state.alert.type} />
                <Component { ...this.props } setAlert={this.setAlert} isLoading={this.isLoading} />
              </div>
            </div>
            <Footer />
          </div>
        </div>

        <a className="scroll-to-top rounded" href="#page-top">
          <i className="fas fa-angle-up"></i>
        </a>
        </LoadingOverlay>
      )
    }
  }

  return withFirebase(Layout)
}
