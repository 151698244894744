import React from 'react'
import SignOutButton from '../SignOutButton'
import PropTypes from 'prop-types'

const UserBadgeBase = (props) => (
  <li className="nav-item dropdown no-arrow">
    <span className="nav-link dropdown-toggle" id="userDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <span className="mr-2 d-none d-lg-inline text-gray-600 small">{props.displayName}</span>
      <img className="img-profile rounded-circle" src={props.photoURL} alt="current logged user" />
    </span>
    {/* Dropdown - User Information */}
    <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
      <SignOutButton />
    </div>
  </li>
)

UserBadgeBase.propTypes = {
  displayName: PropTypes.string.isRequired,
  photoURL: PropTypes.string.isRequired
}

export default UserBadgeBase