import React from 'react'
import { compose } from 'recompose'
import { withLayout } from '../Layout'
import { withFirebase } from '../Firebase'
import { withTranslation, Trans } from 'react-i18next'
import DocumentsTable from '../DocumentsTable'
import Alert from '../Alert'

class DocumentsTablePageContent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      data: [],
      current: null,
      ready: false
    }

  }

  fetchDataWhenNeeded() {
    var currentUser = this.props.firebase.currentUser
    var runFetch = false

    if (currentUser) {
      if (this.state.ready) {
        if (currentUser.uid !== this.state.current.uid) runFetch = true
      } else runFetch = true
    }

    if (runFetch) this.fetchData()
  }

  /***
    * @param String if not provided the first element will be retrieved
    */
  fetchData(groupId) {
    const db = this.props.firebase.db
    var currentUser = this.props.firebase.currentUser
    var fiscalcode = currentUser.fiscalcode
    var group

    if (currentUser.groups.length === 0) {
      this.setState({
        data: [],
        current: {
          uid: currentUser.uid,
          groupId: null
        },
        ready: true
      })
      return
    }

    if (!groupId) group = currentUser.groups[0]
      else group = currentUser.groups.filter(g => g.id === groupId)[0]

    this.props.isLoading(true)
    var query = db.collection("documents")
      .where('group', '==', group.id)

    if (group.access !== 'write') query = query.where('recipient', '==', fiscalcode)
    query = query.orderBy("date", "desc")
    query.get().then((querySnapshot) => {
      var result = []
      querySnapshot.forEach((doc) => {
        let el = doc.data()
        el.id = doc.id

        el.actions = ['open']

        result.push(el)
      })
      this.setState({ 
        data: result, 
        ready: true,  
        current: {
          uid: currentUser.uid,
          groupId: group.id
        }
      })
      this.props.isLoading(false)
    })
  }

  onGroupSelectionChange(groupId) {
    this.setState({
      current: {
        uid: this.state.uid,
        groupId: groupId
      }
    })
  }

  componentDidMount() {
    this.fetchDataWhenNeeded()
  }

  componentDidUpdate() {
    this.fetchDataWhenNeeded()
  }

  recheckUploadedDocs() {
    this.props.isLoading(true)
    this.props.firebase.recheckUploadedDocs()
      .then( () => { 
        this.props.isLoading(false)
      } )
      .catch( (err) => console.error('error in recheckUploadedDocs', err) )
  }

  render() {
    const i18n = this.props.i18n // required for i18n.scanner
    const noDocumentsAlert = (<Alert id = "noDocumentsAlert" content = {i18n.t('No documents to display')} type="info" />)
    var currentUser = this.props.firebase.currentUser

    return (
      <div>
        <div className="row justify-content-end">
        {/*
          <button className="mr-3 btn btn-sm btn-primary" data-toggle="modal" data-target="#DocumentUploadModal">
            <i className="fas fa-upload fa-sm text-white-50"></i> Upload Document
          </button>
        */}
          {
            (currentUser && currentUser.admin) ? (
              <button className="mr-3 btn btn-sm btn-warning">
                <i className="far fa-check-circle fa-sm text-white-50"></i> Recheck uploaded docs
              </button>
            ) : null
          }
        </div>

        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800"><Trans>Documents</Trans></h1>
          {/*<DocumentUploadModal />*/}
        </div>

        {
          this.state.data.length > 0 ?
            <DocumentsTable
              onGroupSelectionChange = { this.onGroupSelectionChange }
              sendAlert = { (a) => this.props.setAlert(a.content, a.type) }
              data = { this.state.data }
              currentGroupId = { this.state.current.groupId }
              groups = { currentUser.groups } />
              :
              noDocumentsAlert
        }



      </div>
    )
  }
}

const DocumentsTablePage = compose(
  withFirebase,
  withTranslation(),
  withLayout
)(DocumentsTablePageContent)

export default DocumentsTablePage
export { DocumentsTablePageContent }
