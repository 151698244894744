import React, { Component } from 'react'
import { withFirebase } from '../Firebase'

class SignInButton extends Component {
  onClick = () => {
    let provider = new this.props.firebase.app.auth.GoogleAuthProvider()
    provider.addScope('profile')
    provider.addScope('email')
    this.props.firebase.app.auth().signInWithRedirect(provider)
  }

  render() {
    return (
      <div>
        <button onClick={this.onClick} className="btn btn-sm btn-primary" type="button">
          Sign In
        </button>
      </div>
    );
  }
}

export default withFirebase(SignInButton)