import React from 'react'
import PropTypes from 'prop-types'
import { Trans } from 'react-i18next'
import './style.css'

class GroupSelector extends React.Component {
  constructor(props) {
    super(props)

    this.onChange = this.onChange.bind(this)
  }

  onChange(event) {
    if (!this.props.onChange) return
    var id = event.target.value
    var selected

    for (let group of this.props.data)
      if (group.id === id) { 
        selected = group
        break
      }


    if (!selected) console.warn(`cannot find id "${id}" in data provided to GroupSelector`, this.props.data)
      else this.props.onChange(id, selected)
  }

  render() {
    var options = this.props.data.map(group => <option 
      key={ group.id } 
      value={ group.id }>

      { group.name }
    </option>)

    return (
      <form className="form-inline">
        <label><Trans>Group</Trans>: </label>
        <select 
          disabled={ this.props.disabled } 
          value={ this.props.selectedId } 
          onChange={ e => this.onChange(e) } 
          className="form-control form-control-sm">

          { options }
        </select>
      </form>
    )
  }
}

const dataType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
})

GroupSelector.propTypes = {
  data: PropTypes.arrayOf(dataType).isRequired,
  disabled: PropTypes.bool,
  selectedId: PropTypes.string,

  /** called as: onChange(String (id), {name: String, id: String}) */ 
  onChange: PropTypes.func
}

export default GroupSelector