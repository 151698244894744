import React from 'react'
import { compose } from 'recompose'
import { withOnlyLogged } from '../Firebase'
import DocumentsTablePage from './table.js'

class Content extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      uploadedFileData: null
    }
  }

  render() {
    return this.state.uploadedFileData ? (<div>ciao</div>) : <DocumentsTablePage />
  }
}

const DocumentsPage = compose(
  withOnlyLogged
)(Content)

export default DocumentsPage