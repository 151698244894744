import React, { Component } from 'react'
import { withFirebase } from '../Firebase'

class SignOutButton extends Component {
  constructor(props) {
    super(props)

    this.onClick = this.onClick.bind(this)
  }

  onClick = () => {
    this.props.firebase.signOut()
  }

  render() {
    return <button className="dropdown-item" onClick={ this.onClick }>
      <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
      Logout
    </button>
  }
}

export default withFirebase(SignOutButton)
