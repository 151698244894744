import React from 'react'
import * as ROUTES from '../../constants/routes'
import PropTypes from 'prop-types'
import { Trans } from 'react-i18next'

class SidebarBase extends React.Component {

  componentDidMount() {
    var $ = window.$

    $("#sidebarToggle, #sidebarToggleTop").on('click', function(e) {
      $("body").toggleClass("sidebar-toggled")
      $(".sidebar").toggleClass("toggled")
      if ($(".sidebar").hasClass("toggled")) {
        $('.sidebar .collapse').collapse('hide')
      }
    })
    
  }

  getItemClasses(currentItem) {
    var list = 'nav-item'
    if (currentItem === this.props.pathname) list += ' active'

    return list
  }

  render () {
    return (
  <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">

    <a className="sidebar-brand d-flex align-items-center justify-content-center" href={ROUTES.DOCUMENTS}>
      <div className="sidebar-brand-icon">
        <img src="logo.png" alt="logo" />
      </div>
      <div className="sidebar-brand-text mx-3">Paycheck</div>
    </a>

    <hr className="sidebar-divider my-0" />

    {
      (this.props.isLogged) ?
      (
      <li className={ this.getItemClasses('/documents') }>
        <a className="nav-link" href={ROUTES.DOCUMENTS}>
          <i className="fas fa-fw fa-file-invoice"></i>
          <span><Trans>Documents</Trans></span></a>
      </li>
      ) : null
    }

    {
      (this.props.isLogged && this.props.hasRecipientsAccess) ?
      (
        <li className={ this.getItemClasses('/recipients') }>
          <a className="nav-link" href={ROUTES.RECIPIENTS}>
            <i className="fas fa-fw fa-address-book"></i>
            <span><Trans>Recipients</Trans></span></a>
        </li>
      ) : null
    }


    <hr className="sidebar-divider d-none d-md-block" />

    <div className="text-center d-none d-md-inline">
      <button className="rounded-circle border-0" id="sidebarToggle"></button>
    </div>

  </ul>
)
  }
}

SidebarBase.propTypes = {
  isLogged: PropTypes.bool.isRequired,
  hasRecipientsAccess: PropTypes.bool.isRequired,
  pathname: PropTypes.string
}

SidebarBase.defaultProps = {
  pathname: '/documents'
}

export default SidebarBase