import React from 'react'
import PropTypes from 'prop-types'
import LoadingOverlay from 'react-loading-overlay'
import RowTableActions from '../RowTableActions'
import _ from 'lodash'

class DataTable extends React.Component {
  render () {
    var columnHeader = (<tr>
      { this.props.columns.map(c => <th key={c.name}>{c.label ? c.label : c.name}</th>) }
    </tr>)

    var data = this.props.data.map(row => {
      let id = row[this.props.idColumnName]
      var cells

      if (this.props.hasActionButtons) {
        cells = _.cloneDeep(row)
        if (cells.actions && this.props.onClickActionButton) {
          cells.actions = (
            <RowTableActions 
              actions = { cells.actions }
              onClickActionButton = { (action) => this.props.onClickActionButton(action, id, row) } />
          )
        } else console.error('Expected "actions" and onClickActionButton field with hasActionButtons option', cells.actions, this.props.onClickActionButton)
      } else cells = row

      let dataSet = this.props.columns.map( cellColumn => 
        <td key={ cellColumn.name }>{ cells[cellColumn.name] }</td>
      )

      return <tr key={id}>{dataSet}</tr>
    })

    return (
      <div className="card shadow mb-4">
        {
          this.props.title ? (
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-primary">{this.props.title}</h6>
            </div>
          ) : null
        }

        <div className="card-body">
          <LoadingOverlay
            active={this.props.isLoading}
            spinner
            >
            { this.props.subHeader ? this.props.subHeader : null }
            <div className="table-responsive">
              <table className="table table-bordered" width="100%" cellSpacing="0">
                <thead>{ columnHeader }</thead>
                <tfoot>{ columnHeader }</tfoot>
                <tbody>{ data }</tbody>
              </table>
            </div>
          </LoadingOverlay>
        </div>
      </div>
    )
  }
}

const columnType = PropTypes.shape({
  label: PropTypes.string,
  name: PropTypes.string.isRequired
})

DataTable.propTypes = {
  title: PropTypes.string,

  columns: PropTypes.arrayOf(columnType).isRequired,

  idColumnName: PropTypes.string,

  data: PropTypes.array.isRequired,

  subHeader: PropTypes.object,

  isLoading: PropTypes.bool,

  /** if true => a column called "actions" must be present in "columns" param and it will be filled with actions array */
  hasActionButtons: PropTypes.bool,

  /** only required if "hasActionButtons" is true */
  onClickActionButton: PropTypes.func
}

DataTable.defaultProps = {
  idColumnName: 'id',
  isLoading: false,
  hasActionButtons: false
}

export default DataTable