import _ from 'lodash'
import app from 'firebase/app'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/firestore'
import 'firebase/functions'

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
}

class Firebase {
  /***
    * @param Object firebase app configuration params
    * @param Function function to be called whenever there is an update in firebase (e.g. currentUser)
    */
  constructor(configuration = config, listenerOnUpdate) {
    if (app.apps.length > 0) console.warn('firebase app already initialized, not initializing it again')
      else app.initializeApp(configuration)

    this.app = app
    this.db = app.firestore()
    this.storageRef = app.storage().ref()
    let functions = app.app().functions('europe-west1')
    if (process.env.NODE_ENV !== 'production') app.functions().useFunctionsEmulator('http://localhost:5000')
    this.listFiles = functions.httpsCallable('listFiles')
    this.pdf2txt = functions.httpsCallable('pdf2txt')
    this.listUsers = functions.httpsCallable('listUsers')
    this.recheckUploadedDocs = functions.httpsCallable('recheckUploadedDocs')
    this.setCustomUserClaims = functions.httpsCallable('setCustomUserClaims')
    this.currentUser = undefined

    app.auth().setPersistence(app.auth.Auth.Persistence.SESSION)
    app.auth().onAuthStateChanged( () => this.retrieveCurrentUser() )

    this.listenerOnUpdate = listenerOnUpdate
  }

  /***
    * retrieve from Firebase current user data and store them in currentUser
    * @return Promise(object)
    */
  retrieveCurrentUser() {
    var currentUser = app.auth().currentUser

    if (!currentUser) {
      this.currentUser = null
      if (this.listenerOnUpdate) this.listenerOnUpdate()
      return Promise.resolve(null)
    }

    this.currentUser = {
      uid: currentUser.uid,
      email: currentUser.email,
      displayName: currentUser.displayName,
      photoURL: currentUser.photoURL,
      groups: [],
      groupMap: {}
    }

    return currentUser.getIdTokenResult()
      // check if claims have to be set
      .then(idTokenResult => {
        let claims = idTokenResult.claims
        const setCustomUserClaims = app.functions().httpsCallable('setCustomUserClaims')

        if (claims.admin === undefined) 
          return setCustomUserClaims(currentUser.uid)
            .then(() => currentUser.getIdToken(true))
            .then(() => currentUser.getIdTokenResult())
          else return idTokenResult
      })
      .then(idTokenResult => {
        let groupIds = [], groupAccessMap
        let claims = idTokenResult.claims

        this.currentUser.fiscalcode = claims.fiscalcode

        groupAccessMap = claims.groups
        for (let id in groupAccessMap) groupIds.push(id)
        this.currentUser.admin = claims.admin

        return Promise.all(
          groupIds.map(groupId => this.db.collection('groups').doc(groupId).get())
        ).then( (snapshots) => {
          snapshots.map( snap => {
            if (!snap.empty)
              this.currentUser.groups.push({
                id: snap.id,
                name: snap.data().name,
                access: groupAccessMap[snap.id]
              })
              else console.warn('empty group for currentUser')
            return null
          })

          this.currentUser.groups.map(g => this.currentUser.groupMap[g.id] = g.name) 
        })
      })
      .then(() => {
        if (this.listenerOnUpdate) this.listenerOnUpdate()
        return this.currentUser
      })
  }

  signOut() {
    this.currentUser = null
    this.app.auth().signOut()
      .then(() => {
        if (this.listenerOnUpdate) this.listenerOnUpdate()
      })
  }

  /***
    * @param String
    * @param {
        component: String
        userEmail: String
        severity: String (options: 'info', 'error')
        details: String     (eventually object serialized)
        context:            'production' | 'test' (if 'test' => only console.error no DB insertion)
    */
  watchdog(event, otherInfo) {
    var data = _.cloneDeep(otherInfo)
    data.event = event
    delete data.context

    if (otherInfo.context !== 'test') {
      data.timestamp = app.firestore.FieldValue.serverTimestamp()
      data.nodeEnv = process.env.NODE_ENV
      data.userAgent = navigator.userAgent
      this.db.collection('watchdog').add(data)
        .catch(err => console.error(err))
    } else {
      console.debug('watchdog', data)
    }
  }

  /***
    * This function get a custom token to bypass real login
    * It will work only in development env
    */
  fakeLogin(email) {
    var getCustomToken = app.functions().httpsCallable('getCustomToken')
    var setCustomUserClaims = app.functions().httpsCallable('setCustomUserClaims')
    return getCustomToken({email: email})
      // to update claims in case of manually update of db
      .then( result => {
        return setCustomUserClaims(result.data.user.uid)
          .then( () => result )
      })
      .then( result => this.app.auth().signInWithCustomToken(result.data.token) )
      .catch((err) => {
        console.error(err)
        throw new Error(err)
      })
  }

}

export default Firebase;