import React from 'react'
import { withFirebase } from '../Firebase'
import UserBadgeBase from './base.js'
import SignInButton from '../SignInButton'

const CurrentUserBadge = (props) => props.firebase.currentUser ? (
  <UserBadgeBase 
    displayName={props.firebase.currentUser.displayName}
    photoURL={props.firebase.currentUser.photoURL} />
) : (<SignInButton />)

export default withFirebase(CurrentUserBadge)