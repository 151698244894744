import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { withFirebaseProvider } from '../Firebase'
import * as ROUTES from '../../constants/routes'

import LandingPage from '../LandingPage'
import DocumentsPage from '../DocumentsPage'
//import RecipientsPage from '../RecipientsPage'

const App = () => (
  <Router>
    <Route exact path={ROUTES.LANDING} component={LandingPage} />
    <Route path={ROUTES.DOCUMENTS} component={DocumentsPage} />
  </Router>
)

export default withFirebaseProvider(App)