import React from 'react'
import UserBadge from '../UserBadge'

const Topbar = (props) => (
  <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">

    <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
      <i className="fa fa-bars"></i>
    </button>

    {/* Topbar Navbar */}
    <ul className="navbar-nav ml-auto">

      <div className="topbar-divider d-none d-sm-block"></div>

      <UserBadge />

    </ul>

  </nav>
)

export default Topbar