import React from 'react'
import { Trans } from 'react-i18next'

const Footer = (props) => (
  <footer className="sticky-footer bg-white">
    <div className="container my-auto">
      <div className="copyright text-center my-auto">
        <span><Trans>Paycheck is a <a href="http://wink.by">WiNK</a> product</Trans></span>
      </div>
    </div>
  </footer>
)

export default Footer