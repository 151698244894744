import React from 'react'
import PropTypes from 'prop-types'

const OpenButton = (props) => (
  <button 
    onClick={() => props.onClickActionButton('open')}
    type="button"
    className="btn btn-circle btn-sm btn-primary">

    <i className="fas fa-external-link-square-alt"></i>
  </button>
)

const DeleteButton = (props) => (
  <button 
    onClick={() => props.onClickActionButton('delete')} 
    type="button" 
    className="btn btn-circle btn-sm btn-danger">

    <i className="fas fa-trash"></i>
  </button>
)

class RowTableActions extends React.Component {
  render() {
    var content = this.props.actions.map(action => {
      var component
      switch(action) {
        case 'open': component = (<OpenButton key="open" onClickActionButton = { this.props.onClickActionButton } />); break
        case 'delete': component = (<DeleteButton key="delete" onClickActionButton = { this.props.onClickActionButton } />); break
        default:
          console.warn(`unrecogned action: ${action}`)
      }
      return component
    })
    return (<div className="data-table-action-field">{ content }</div>)
  }
}

RowTableActions.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.oneOf(['open', 'edit', 'delete'])
  ).isRequired,
  onClickActionButton: PropTypes.func.isRequired
}

export default RowTableActions