import React from 'react'
import { compose } from 'recompose'
import { withFirebase } from '../Firebase'
import { withTranslation } from 'react-i18next'
import DataTable from '../DataTable'
import GroupSelector from '../GroupSelector'
import PropTypes from 'prop-types'
import './style.css'

class DocumentsTableContent extends React.Component {
  constructor(props) {
    super(props)

    this.rowTableActions = this.rowTableActions.bind(this)
  }

  manageErrorMessage(err) {
    const i18n = this.props.i18n // required for i18n.scanner
    var result = i18n.t('internal error')

    if (err.indexOf('does not exist.') !== -1) result = i18n.t('file not found')

    return result
  }

  openDocument(path) {
    this.props.firebase.storageRef.child(path).getDownloadURL().then(function(url) {
      window.open( url, '_blank' )
    })
    .then(() => {
      this.props.firebase.watchdog('document opened', {
        context: this.props.context,
        component: this.constructor.name,
        userEmail: this.props.currentUserEmail ? this.props.currentUserEmail : 'not logged',
        severity: 'info',
        details: path
      })
    })
    .catch((err) => {
      var message = this.manageErrorMessage(err.message)
      this.props.sendAlert({
        content: message,
        type: 'danger'
      })

      this.props.firebase.watchdog(err.message, {
        context: this.props.context,
        component: this.constructor.name,
        userEmail: this.props.currentUserEmail ? this.props.currentUserEmail : 'not logged',
        severity: 'error',
        details: path
      })
    })
  }

  rowTableActions(action, id, row) {
    switch(action) {
      case 'open': 
        this.openDocument(row.path)
        break
      default:
        console.error(`unrecognized action: ${action}`)
    }
  }

  render() {
    const i18n = this.props.i18n // required for i18n.scanner
    const tableColumns = [
      { name: 'actions', label: i18n.t('Actions') },
      { name: 'date', label: i18n.t('Date') },
      { name: 'title', label: i18n.t('Title') },
      { name: 'type', label: i18n.t('Type') },
      { name: 'recipient', label: i18n.t('Recipient') }
    ]

    const tableSubHeader = (
      this.props.groups.length > 1 ?
      <div className="groupSelector">
        <GroupSelector 
          onChange={ this.props.onGroupSelectionChange } 
          selectedId={ this.props.currentGroupId }
          data={ this.props.groups } />
      </div>
      : null
    )

    return (
      <DataTable
        isLoading = { this.props.isLoading }
        title = {i18n.t('Documents Table')}
        columns = { tableColumns }
        data = { this.props.data }
        hasActionButtons = { true }
        onClickActionButton = { this.rowTableActions }
        subHeader = { tableSubHeader } />
    )
  }
}

const DocumentsTable = compose(
  withFirebase,
  withTranslation()
)(DocumentsTableContent)

DocumentsTable.propTypes = {
  data: PropTypes.array.isRequired,

  /*** [{id: String, name: String}] */
  groups: PropTypes.array.isRequired,

  currentGroupId: PropTypes.string,

  onGroupSelectionChange: PropTypes.func.isRequired,

  sendAlert: PropTypes.func.isRequired,

  context: PropTypes.oneOf(['production', 'test']),

  isLoading: PropTypes.bool,

  /***  used only for watchdog */
  currentUserEmail: PropTypes.string
}

DocumentsTable.defaultProps = {
  context: 'production',
  isLoading: false
}

export default DocumentsTable