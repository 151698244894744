import React from 'react'
import { compose } from 'recompose'
import SidebarBase from './base'
import { withFirebase } from '../Firebase'
import { withRouter } from 'react-router-dom'

const Sidebar = (props) => (
  <SidebarBase
    isLogged={ !!props.firebase.currentUser }
    hasRecipientsAccess={ ( 
        props.firebase.currentUser && 
        props.firebase.currentUser.groups.filter(g => g.access === 'write').length > 0 
      ) ? true : false }
    pathname={ props.location.pathname } />
)

export default compose(withRouter, withFirebase)(Sidebar)