import React from 'react'
import PropTypes from 'prop-types'
import './style.css'

const Alert = ({id, type, content}) => (
  content ? (
  <div id={ id } className={`alert alert-${type} fade show`} role="alert">
    {content}
  </div>
  ) : null
)

Alert.propTypes = {
  type: PropTypes.string,
  content: PropTypes.string,
  id: PropTypes.string
}

Alert.defaultProps = {
  id: 'alert'
}

export default Alert