import React from 'react'
import { Redirect } from 'react-router-dom'
import { withLayout } from '../Layout'
import { withFirebase } from '../Firebase'
import * as ROUTES from '../../constants/routes'

const LandingBase = withLayout(
  () =>
  (
    <div>
      <h1>Landing</h1>
    </div>
  )
)

const Landing = (props) => {
  return props.firebase.currentUser ?
    <Redirect to={ROUTES.DOCUMENTS} /> :
    <LandingBase />
}




export default withFirebase(Landing)