import React from 'react'
import ReactDOM from 'react-dom'

import './index.css'
import './components/I18n'
import * as serviceWorker from './serviceWorker'

import App from './components/App'

ReactDOM.render(<App /> , document.getElementById('root'))

serviceWorker.unregister();