import React from 'react'
import { Redirect } from 'react-router-dom'
import * as ROUTES from '../../constants/routes'
import Firebase from './firebase.js'

const FirebaseContext = React.createContext(null)

export const withFirebaseProvider = (Component, firebaseConfig) => {

  class WithFirebaseProvider extends React.Component {
    constructor(props) {
      super(props)
      this.firebase = new Firebase(firebaseConfig, () => this.setState({firebase: this.firebase}))

      this.state = {
        firebase: this.firebase
      }
    }

    render() {
      return (<FirebaseContext.Provider value={ this.state.firebase }>
        <Component {...this.props} />
      </FirebaseContext.Provider>)
    }
  }

  return WithFirebaseProvider
}

export const withFirebase = Component => props => {
  return (
  <FirebaseContext.Consumer>
    {firebase => <Component {...props} firebase={ firebase } />}
  </FirebaseContext.Consumer>
)}

export const withOnlyLogged = Component => {

  const WithOnlyLoggedBase = (props) => (
    props.firebase.currentUser !== null ? (<Component {...props} />) : (<Redirect to={ROUTES.LANDING} />)
  )

  return withFirebase(WithOnlyLoggedBase)
}


export default FirebaseContext